import {
  DaysOfTheWeek,
  McpNotificationFragment,
  useMarkNotificationAsReadMutation,
} from '@wise/graphql'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { route } from '~generated/routes'
import CalendarIcon from '~shared/components/Icons/svg/calendar.svg'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'

import BaseNotification from './BaseNotification'

interface Props {
  notification: McpNotificationFragment & {
    __typename: 'SchedulingRejectedRouteNotification'
  }
  older: boolean
  markAsRead: (id: string) => void
}

const SchedulingRejectedRoute = ({
  notification,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const router = useRouter()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const handleClick = useCallback(async () => {
    markAsRead(notification.id)

    if (!notification.read) {
      try {
        const result = await markNotificationAsRead({
          variables: {
            input: {
              notificationId: notification.id,
              userId: notification.sc.id,
            },
          },
        })
        if (!result.data?.markNotificationAsRead) return
        markAsRead(notification.id)
      } catch (error) {
        triggerErrorModal({ error })
      }
    }

    const routeDay = dayjs(notification.date)
      .format('dddd')
      .toUpperCase() as DaysOfTheWeek

    router.push(
      route(
        '/scheduling/[mcId]/[depotId]',
        notification.mcId,
        notification.route.locationId,
      ) +
        `?date=${notification.date}&user=${notification.sc.id}&route=${routeDay}`,
    )
  }, [markAsRead, markNotificationAsRead, notification, router])

  return (
    <div
      className={cx(
        'mb-3 flex min-w-max cursor-pointer items-center rounded-lg border-2 border-grey p-3',
        notification.read ? 'bg-white' : 'bg-red/5',
      )}
      onClick={handleClick}
    >
      <BaseNotification
        notification={notification}
        older={older}
        category='Rejected Route'
      >
        <div className='flex items-center'>
          <div className='mr-1 flex h-4 w-4 items-center justify-center rounded-full bg-grey-dark'>
            <CalendarIcon className='text-[0.6rem]' />
          </div>
          <p>
            {dayjs(
              new Date(notification.date) ?? new Date(notification.createdAt),
            ).format('dddd DD MMM YYYY')}
          </p>
        </div>
        <div className='ml-2 h-1 w-1 rounded-full bg-grey-800'></div>
        <div className='ml-2 flex items-center'>
          <div className='mr-1 flex h-4 w-4 items-center justify-center rounded-full bg-grey-dark text-grey-800'>
            <CalendarIcon className='text-[0.6rem]' />
          </div>
          {notification?.route?.name || '???'}
        </div>
      </BaseNotification>
    </div>
  )
}

export default SchedulingRejectedRoute
