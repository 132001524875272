import { useMarkNotificationAsReadMutation } from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'
import cx from 'classnames'
import dayjs from 'dayjs'
import { startCase } from 'lodash'
import { useRouter } from 'next/router'
import * as React from 'react'

import { route } from '~generated/routes'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import {
  ExpiringEvidenceNotification,
  ExpiringVehicleInsuranceNotification,
} from '~shared/graphql/types'

import { isExpired } from '../utils/notifications'

import BaseNotification from './BaseNotification'

interface Props {
  notification:
    | ExpiringEvidenceNotification
    | ExpiringVehicleInsuranceNotification
  older: boolean
  markAsRead: (id: string) => void
}

const EngagementExpiringEvidence = ({
  notification,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const router = useRouter()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const handleClick = React.useCallback(async () => {
    markAsRead(notification.id)

    if (!notification.read) {
      try {
        const result = await markNotificationAsRead({
          variables: {
            input: {
              notificationId: notification.id,
              userId: notification.sc.id,
            },
          },
        })
        if (!result.data?.markNotificationAsRead) return
        markAsRead(notification.id)
      } catch (error) {
        triggerErrorModal({ error })
      }
    }

    switch (notification.__typename) {
      case 'EngagementExpiringEvidenceNotification':
        return router.push(
          route('/drivers/[id]/engagement-details', notification.sc.id),
        )
      case 'ExpiringVehicleInsuranceNotification':
        return router.push(route('/drivers/[id]/vehicle', notification.sc.id))
    }
  }, [
    markAsRead,
    markNotificationAsRead,
    notification.__typename,
    notification.id,
    notification.read,
    notification.sc.id,
    router,
  ])

  const documentType = React.useMemo(() => {
    switch (notification.__typename) {
      case 'EngagementExpiringEvidenceNotification': {
        if (notification.document.__typename === 'Evidence') {
          return notification.document.template.name
        } else {
          const type = notification.document.data?.type
          if (!isNonEmptyString(type)) return null
          return startCase(type.toLowerCase())
        }
      }
      case 'ExpiringVehicleInsuranceNotification':
        return 'Vehicle Insurance'
    }
  }, [notification])

  const isEvidenceExpired = React.useMemo(
    () => isExpired(notification.expiryDate),
    [notification.expiryDate],
  )

  const expiringEvidenceStyle = React.useMemo(() => {
    if (!isEvidenceExpired && !notification.read) {
      return { bgColor: 'bg-[#fcf6e1]', categoryColor: 'bg-[#f7df94]' }
    }

    if (isEvidenceExpired && !notification.read) {
      return { bgColor: 'bg-red/5', categoryColor: 'bg-[#f2b6b6] text-red' }
    }

    return { bgColor: 'bg-white', categoryColor: 'bg-[#f5f5f5]' }
  }, [isEvidenceExpired, notification.read])

  return (
    <div
      className={cx(
        'mb-3 flex min-w-max cursor-pointer items-center rounded-lg border-2 border-grey p-3',
        expiringEvidenceStyle.bgColor,
      )}
      onClick={handleClick}
    >
      <BaseNotification
        notification={notification}
        older={older}
        category={isEvidenceExpired ? 'Expired' : 'Due to expire'}
        customStyle={expiringEvidenceStyle.categoryColor}
      >
        <div className='flex items-center'>
          {isEvidenceExpired ? (
            <p>
              {documentType ?? '-'} <span className='font-bold'>expired </span>
              on{' '}
              {dayjs(new Date(notification.expiryDate)).format(
                'dddd DD MMM YYYY',
              )}
            </p>
          ) : (
            <p>
              {documentType ?? '-'} will
              <span className='font-bold'> expire </span>on{' '}
              {dayjs(new Date(notification.expiryDate)).format(
                'dddd DD MMM YYYY',
              )}
            </p>
          )}
        </div>
      </BaseNotification>
    </div>
  )
}

export default EngagementExpiringEvidence
