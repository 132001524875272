import { useMarkNotificationAsReadMutation } from '@wise/graphql'
import { isNonEmptyString, showToastNotification } from '@wise/utils'
import cx from 'classnames'
import { startCase } from 'lodash'
import { useRouter } from 'next/router'
import * as React from 'react'

import { route } from '~generated/routes'
import { ReuploadedEvidenceNotification } from '~shared/graphql/types'

import BaseNotification from './BaseNotification'

interface Props {
  notification: ReuploadedEvidenceNotification
  older: boolean
  markAsRead: (id: string) => void
}

const ReuploadedEvidence = ({
  notification,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const router = useRouter()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const handleClick = React.useCallback(async () => {
    markAsRead(notification.id)

    if (!notification.read) {
      try {
        const result = await markNotificationAsRead({
          variables: {
            input: {
              notificationId: notification.id,
              userId: notification.sc.id,
            },
          },
        })
        if (!result.data?.markNotificationAsRead) return
        markAsRead(notification.id)
      } catch (error) {
        showToastNotification({
          type: 'error',
          description:
            'The notification could not be marked as read. Please try again later.',
        })
      }
    }

    return router.push(
      route('/drivers/[id]/engagement-details', notification.sc.id),
    )
  }, [
    markAsRead,
    markNotificationAsRead,
    notification.id,
    notification.read,
    notification.sc.id,
    router,
  ])

  const documentType = React.useMemo(() => {
    switch (notification.document.__typename) {
      case 'Document': {
        const type = notification.document.data?.type
        if (!isNonEmptyString(type)) return null
        return startCase(type.toLowerCase())
      }
      case 'Evidence': {
        return notification.document.template.name
      }
    }
  }, [notification])

  return (
    <div
      className={cx(
        'mb-3 flex min-w-max cursor-pointer items-center rounded-lg border-2 border-grey p-3',
        notification.read ? 'bg-white' : 'bg-red/5',
      )}
      onClick={handleClick}
    >
      <BaseNotification
        notification={notification}
        older={older}
        category='Evidence re-uploaded'
        customStyle='bg-green/25'
      >
        <div className='flex items-center'>
          <p>
            A new document has been uploaded for{' '}
            <b>{documentType ?? 'an unknown document'}</b>.
          </p>
        </div>
      </BaseNotification>
    </div>
  )
}

export default ReuploadedEvidence
