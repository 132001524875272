import type { McpNotificationFragment, User } from '@wise/graphql'
import cx from 'classnames'
import dayjs from 'dayjs'

import DriverProfile from '~shared/components/DriverProfile/DriverProfile'

import { getDriverName } from '@/subcontractors/tables/helpers'

interface Props {
  notification: McpNotificationFragment
  category?: string
  older: boolean
  children?: React.ReactNode
  customStyle?: string
}

const BaseNotification = ({
  notification,
  category,
  older,
  children,
  customStyle,
}: Props): JSX.Element => {
  const formatCategory = (
    category: McpNotificationFragment['__typename'],
  ): string => {
    switch (category) {
      case 'SchedulingRejectedRouteNotification':
        return 'Rejected Route'
      default:
        return 'Unknown'
    }
  }

  return (
    <>
      {'sc' in notification ? (
        <div className='mr-1 flex'>
          <DriverProfile
            driver={notification.sc as User}
            name={false}
            className='!text-base'
          />
        </div>
      ) : null}
      <div className='mr-1 w-full min-w-max'>
        <div className='mb-1 flex items-center'>
          <p className='mr-2 text-sm font-bold text-black'>
            {'sc' in notification ? getDriverName(notification.sc) : '???'}
          </p>
          <p
            className={cx(
              'mr-2 rounded-full py-[0.07rem] px-2 text-sm',
              customStyle
                ? customStyle
                : notification.read
                ? 'bg-[#f5f5f5]'
                : 'bg-[#f2b6b6]',
            )}
          >
            {category || formatCategory(notification.__typename)}
          </p>
          <p className='text-grey-darker'>
            {older
              ? dayjs(notification.createdAt).format('ddd, DD MMM YY')
              : dayjs(notification.createdAt).format('HH:mm')}
          </p>
        </div>
        <div className='flex items-center'>{children || null}</div>
      </div>
    </>
  )
}

export default BaseNotification
