import {
  McpNotificationFragment,
  useMarkNotificationAsReadMutation,
} from '@wise/graphql'
import { invariant } from '@wise/utils'
import cx from 'classnames'
import { useCallback } from 'react'

import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import { useUser } from '~shared/services/firebase/auth/hooks'

import BaseNotification from './BaseNotification'

interface Props {
  notification: McpNotificationFragment
  older: boolean
  markAsRead: (id: string) => void
}

const DefaultNotification = ({
  notification,
  older,
  markAsRead,
}: Props): JSX.Element => {
  const user = useUser()
  const [markNotificationAsRead] = useMarkNotificationAsReadMutation()

  const handleClick = useCallback(async () => {
    markAsRead(notification.id)

    if (!notification.read) {
      try {
        invariant(user, 'User is not authenticated')
        const result = await markNotificationAsRead({
          variables: {
            input: {
              notificationId: notification.id,
              userId: user.id,
            },
          },
        })
        if (!result.data?.markNotificationAsRead) return
        markAsRead(notification.id)
      } catch (error) {
        triggerErrorModal({ error })
      }
    }
  }, [
    markAsRead,
    markNotificationAsRead,
    notification.id,
    notification.read,
    user,
  ])

  return (
    <div
      className={cx(
        'mb-3 flex min-w-max cursor-pointer items-center rounded-lg border-2 border-grey p-3',
        notification.read ? 'bg-white' : 'bg-red/5',
      )}
      onClick={handleClick}
    >
      <BaseNotification
        notification={notification}
        older={older}
      ></BaseNotification>
    </div>
  )
}

export default DefaultNotification
